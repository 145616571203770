import { api } from ":mods";
import { ENDPOINTS } from "../../const";

export async function putQuestion(id:string|number,collection_id:string|number,data) {
  const collection = `?collection=${collection_id}`
      return api
        .putAuth(ENDPOINTS.material_details_with_material_id_student + id+"/" + collection, data, {
          headers: {
            "Content-Type":  "application/json",
          },
        })
        .then(async (res) => {
          return Promise.resolve(res.data);
        })
        .catch((err) => {
          return Promise.reject(err);
        });

    }